body{
  margin: 0;
}

*{
  scroll-behavior: smooth;
}


.dark{
background-color: black;
}

.light-background{
  background-color: #ededed;
}

.qvote{
font-style: italic;
color: white;
text-align: center;
font-weight: 300;
font-family: 'Mukta', sans-serif;
margin: 25px 10px;
z-index: 2;
}

.black{
  color: black;
}

.light{
  color: white ;
}

.large{
font-size: 50px;
letter-spacing: 2.5px;
}

.tiny{
font-size: 16px;
font-weight: 300;
}

.mini{
  font-size: 12px;
  font-weight: 300;
}

.to-qvote{
  text-align: end;
  margin-top: 10px;
}

.title{
color: white;
font-size: 30px;
text-align: center;
font-weight: bold;
font-family: 'Mukta', sans-serif;
margin: 25px 10px;
z-index: 2;
line-height: 50px;
}


.bold-title{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  margin-left: 5px ;
  margin-right: 5px;
  text-align: center;
}

.letter-spacing{
  letter-spacing: 1px;
}

.small-title{
 font-size: 18px;
 font-weight: 500;
 font-family: 'Noto Sans JP', sans-serif;
 letter-spacing: 1.3px;
 text-align: center;
}

p{
  font-family: 'Noto Sans JP', sans-serif;
}

.p20{
font-size: 20px;
}

.p18{
font-size: 18px;
}

.bold300{
  font-weight: 300 !important;
}

.button{
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1rem;
  background-color: #F11508;
  width: 189px;
  height: 41px;
  border-radius: 4px;
  font-family: sans-serif;
  border: none;
  margin: 50px 0px;
  cursor: pointer;
}

.a-button{
  z-index: 2;

}

.dark-theme{
  background-color: black;
  color: white;
  padding: 100px 0;
}

.thin-paragraf{
  font-size: 17px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 100;
  letter-spacing: .5px;
}

.paragraf{
  font-size: 17px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: .5px;
  text-align: center;
  margin: 40px;
  width: 50%;
}

.hero-paragraf{
  font-size: 1rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 100;
  line-height: 25px;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 40px;
  width: 90%;
  max-width: 950px;
  color: rgb(253, 253, 253);
  z-index: 2;
}

.section-padding{
  padding: 100px 0;
}

.line{
  width: 90vw;
  height: 1px;
  background-color: #626262;
  margin: 100px;
}

.none{
  display: none;
}

.dark-text{
  color: black !important;
}

.a-center{
  display: flex;
  align-items: center;
}

.i2{
  z-index: 2;
}

.cusive{
  font-style: italic;
}

.box-none{
  display: none !important;
}

.block{
  display: flex !important;
}

.m10{
  margin: 10px !important;
}

.m30{
  margin: 30px !important;
}

.m70{
  margin: 70px !important;
}

.pointer{
  cursor: pointer;
}

.h140{
  height: 140px;
}

.no-padding-top{
  padding-top: 0 !important;
}

.t-align-s {
  text-align: start;
}

.t-align-c {
  text-align: center;
  display: block;
}

nav{
  background-color: black;
  margin: 0;
  position: fixed;
  height: 40px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

#company-name{
margin-left: 35px ;
height: 100%;
}

#burger-menu{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  row-gap: 4vh;
  top: 0;
  right: 0;
  width: 0px;
  max-width: 70vw;
  background-color: #1c1c1c;
  opacity: .9;
  height: 100vh;
  transition-duration: .7s;
  overflow: hidden;
}

.burger-title{
  font-size: 21px;
}

#burger-div-wrapper{
  position: absolute;
  right: 38px;
  z-index: 10;
}

#burger-menu a{
  text-decoration: none;
}


#first-section{
padding: 100px 20px;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
position: relative;

background-image: url(../src/media/Handstand-hd.webp);
background-size: cover;
background-position: center;

}

.dark-bg{
  top: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

#offer-section{
  background-image: url(../src/media/Erbjudande-hd.webp);
  background-size: cover;
  background-position: center;

}

.offer-check-wrapper{
  display: flex;
  justify-content: space-between;
  width: 60%;
  flex-wrap: wrap;
}

#offer-text-wrapper{
  width: 48vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 100px 0;

}

.offer-check-icon{
  margin-right: 10px;
  font-size: 22px;
}


#about-section{
padding: 100px 0px;
background: rgb(33,38,19);
background: linear-gradient(180deg, rgba(33,38,19,1) 0%, rgba(8,42,36,1) 65%);
display: flex;
flex-direction: column;
align-items: center;
}

#profile-img{
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 20px;
}

#principer{
  background-image: url(./media/outdoor-gym-hd.webp);
  color: white;
  position: relative;
  background-position-x: center;
}

.p-next-each-other-div{
display: flex;
flex-direction: column;
width: 450px;
flex-wrap: wrap;
align-items: center;
margin: 30px;
z-index: 2;
}

#principles-text-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.more-margin{
margin: 70px;
}

.section-center{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.principle-p{
  max-width: 60%;
  text-align: center;
  letter-spacing: 1.5px;
  line-height: 25px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(226, 226, 226);
  background-color: black;
}

.footer-section a{
  color: rgb(226, 226, 226);
}

#footer-logo{
  width: 75%;
}

.box-wrapper{
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
}

.box-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.box{
display: flex;
color: black;
flex-direction: column;
background-color: #D9D9D9;
text-align: center;
width: 25vw;
min-width: 285px ;
min-height: 265px;
padding: 40px 30px 40px 30px ;
margin: 50px;
border-radius: 4px;
}

.table-article{
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
}

.priser-table{
  border-collapse: collapse;
  margin: 40px 15px;
  font-size: 18px;
  width: 409px;
  max-width: 80vw;
}

.priser-table td, 
.priser-table th{
  color: black;
  border: 1px solid gray;
  line-height: 30px;
  padding: 0px 15px;
  line-height: 50px;
}

#offer{
  width: 100vw;
  height: 0px;
  padding: 0px;
  overflow: hidden;
  transition-duration: .5s ;
}

.offer-box{
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #212613;
  color: #e1e1e1;
  border-radius: 4px;
  padding: 2px 51px;
  margin: 30px 30px;
}

.offer-box p{
  line-height: 30px;
  margin: 10px 0px;
}

.price{
  letter-spacing: 1px;
}

.more-space{
  width: 90vw; 
}

.offer-text-article{
display: flex;
justify-content: center;
flex-wrap: wrap;
}

.offer-text-div{
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px;
}

.offer-text-div ul{
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.offer-text-div li{
  text-align: start;
  width: 100%;
  line-height: 40px;
}

.offer-text-p{
  font-size: 17px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
  letter-spacing: .5px;
  line-height: 30px;
  text-align: center;
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.more-h-div{
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
}


#footer-form-button{
width: 97%;
height: 51px !important;
}

.footer-info{
  color: black;
}


#extra-h{
  height: 0px;
  overflow: hidden;
  padding: 0px 0;
  transition-duration: .6s;
}


#meta-contact-wrapper{
  height: 800px;
  background-image: url(./media/woods-1000kb.webp);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


#contact-section{
  font-family: 'Noto Sans JP', sans-serif;
  width: 100%;
}

.form-box{
  width: 760px;
  min-height: 446px;
  padding: 15px 30px 40px 30px;
  max-width: 80vw;
  background-color: rgba(0,0,0, .85);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center ;
  position: relative;
}

.all-boxes{
  width: 100%;
  height: 100%;
  transition-duration: .3s;

}

.contact-form-number{
  color: #b1b1b1;
  font-size: 18px;
  align-self: center;
}

.first-box{
  opacity: 1;
  display: flex;
  justify-content: center;
}

#second-box{
  opacity: 0;
  transition-duration: .3s;
  align-items: center;  
  flex-direction: column;
}

#third-box{
  opacity: 0;
  transition-duration: .3s;
  align-items: center;  
  flex-direction: column;
}

#forth-box{
  opacity: 0;
  transition-duration: .3s;
  align-items: center;  
  flex-direction: column;
}

/* LÄgg till de andra boxarna: Ta bort denna kommentar. */
#fift-box{
  /* opacity: 0; */
  transition-duration: .3s;
  display: flex;
  align-items: center;  
  flex-direction: column;
  padding-bottom: 90px;
}

.form-paragraf{
font-size: 16px;
margin: 10px;
width: 84%;
}
.contact-form-low-text{
color: #d9d9d9;
font-size: 12px;
}

.contact-box-title{
  color: white;
font-size: 30px;
text-align: center;
font-weight: 100;
font-family: 'Mukta', sans-serif;
margin: 30px 0 5px 0;
z-index: 2;
letter-spacing: 1px;
}

.form-button{
  width: 80%;
  bottom: 20px;
}

.close-icon{
  color: white;
  position: absolute;
  top: 10px;
  right: 17px;
  font-size: 35px;
  padding: 10px;
  cursor: pointer;
}

.checkbox-icon{
  margin-top: 1px;
  margin-right: 10px;
  font-size: 24px;
  cursor: help;
}

.contact-btn-wrapper{
  width: 88%;
  display: flex;
  position: absolute;
  bottom: 30px;
  justify-content: center;
  left: 6%;
}

.box-form-first-button{
  position: absolute;
}

.input-btn{
  width: 40%;
  margin: 15px !important;
  height: 51px !important;
}


.checkbox-list-wrapper,
.inputs input,
#form-article textarea{
  font-size: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 1px;
}

input::placeholder, 
textarea::placeholder {
  color: #6d6d6d;
}

#form-article input, 
#form-article textarea, 
.checkbox{
  margin: 10px ;
  border-radius: 4px;
}

#form-article input, 
.checkbox{
 height: 49px;
}

#form-article input{
  height: 30px;
}

.inputs input,
#form-article textarea{
  padding-left: 5px ;
  font-weight: 300;
  height: 160px;
  background-color: #D9D9D9;

}

.checkbox-list{
  display: flex;
  flex-direction: column;
}

.form-checkbox{
  width: 16px;
  height: 16px;
  margin-top: 6px;
  margin-right: 30px;
  cursor: pointer;
}

#form-article form{
  display: flex;
  flex-direction: column;
}

#form-article{
  max-width: 75%;
  min-width: 300px ;
  display: flex;
  flex-direction: column;
}

.checkbox-list-wrapper{
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.checkbox{
  display: flex;
  align-items: center;
  font-weight: 100;
  font-size: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 1px;
  color: white;
  cursor: pointer;
  margin: 25px;
}


.inputs{
  display: flex;
  justify-content: space-between;
}

.inputs input{
  width: 45% ;
}

.second-checklist-column{
  align-items: flex-end;
}



#form-article textarea{
  padding-top: 5px ;
  height: 130px;
}


.form-btn{
  margin: 10px 10px 140px 10px;
  width: calc(100% - 19px);
}


#contact-info-article{
  font-weight: 100;
  letter-spacing: 1px;
}

#contact-info-article a{
  color: white;
}

.info-with-space{
display: flex;
align-items: center;
justify-content: space-between;
width: 20vw;
max-width: 400px;
min-width: 326px ;
margin: 15px 0;

}

.icons{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 45px 0;
}

.icon{
  font-size: 32px;
  cursor: pointer;
  color: white;
}



.skill-modal{
height: 80vh;
width: 90vw;
margin: 10vh 5vw;
position: fixed;
top: 0px;
left: 0px;
background-color: #252525;
z-index: 3;
border-radius: 5px;
color: white;
display: flex;
flex-direction: column;
overflow: auto;
transition-duration: .3s;
opacity: 0;
cursor: auto;
}

.modal-img-text-wrapper{
display: flex;
flex-wrap: wrap;
justify-content: space-around;
}

.skill-modal-paragraf{
  width: 40%;
  margin-top: 20px;
}

.skill-modal-img{
  width: 40%;
  object-fit: contain;
  align-self: flex-start;
}


.loader-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  max-width:  100vw;

  background-color: rgba(0, 0, 0, 0.2);

  opacity: 1;
  transition-duration: 0.4s ;
  display: none;
}

.modal-loader-wrapper{
  display: block;
  height: 45px;
  width: 45px;
  background-color: rgb(245, 245, 245);
  position: relative;
  border: solid 2px gray;
  border-radius: 10px;
  border-radius: 50%;
  border-top: solid 2px black;
}

.modal-loader-wrapper-animation{
  animation: spinner 2s linear infinite;
}

.done-text-div{
opacity: 0;
transition-duration: 0.4s;
display: flex;
justify-content: space-evenly;
align-items: center;
height: 100%;
}

.done-text{
opacity: 0; 
margin: 0;
color: green;
transition-duration: 0.4s;
font-size: 20px;
}


.modal-loader-new-animation{
  animation: done 0.4s ease-in-out ;
  animation-fill-mode: both;
}


.check-icon{
  font-size: 31px;
  color: green;
}

.message-fail-animation{
  animation: messageFail 0.4s ease-in-out ;
  animation-fill-mode: both;
}

.fail{
  color: red !important;
}


@keyframes messageFail {
  10%{
    border: solid 2px gray;
    }
    100%{
      border: solid 2px red;
      border-radius: 20px;
      width: 360px;
      
    }
}

@keyframes done {
  10%{
  border: solid 2px gray;
  }
  100%{
    border: solid 2px green;
    border-radius: 20px;
    width: 360px;
    
  }

}


@keyframes spinner {
  to { transform: rotate(360deg); }
}

.loader-wrapper{
  height: 45px;
  width: 275px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-wrapper-done{
  height: 90px;
  width: 275px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.done-modal-div{
  display: flex;
  align-items: center;
}

.modal-wrapper-done h1{
  margin: 5px;
}

.check-icon{
  font-size: 31px;
  color: green;
}

.fail{
  color: red !important;
}








@media screen and (max-width: 1200px) {
  .large{
    font-size: 30px;
  }

  .to-qvote{
    text-align: center;
    font-size: 14px;
    margin-top: 15px ;
  }

  #company-name{
    margin-left: 15px;
  }

    #offer-text-div{
      width: 90vw;
      margin: 0 5vw;
    }

    .more-margin{
      margin: 30px 10px;
    }

    .p-next-each-other-div{
      justify-content: center;
      width: 70vw;
    }

    .principle-p {
    max-width: 100%;
    }

    .offer-text-div{
      width: 80%;
    }

    .inputs input, 
    #form-article textarea{
      font-size: 16px;
      height: 80px;
    }
    
    .offer-text-p{
      margin-right: 0;
      margin-left: 0;
    }

    .margin-sides{
      margin-left: 20px;
      margin-right: 20px;
    }

    .box{
      margin-left: 0;
      margin-right: 0;
    }

    #offer-text-wrapper{
      width: 100vw;
    }

}

@media screen and (max-width: 810px) {

#offer-section{
  background-position-x: 920px;
 }
 
 .skill-modal{
  height: 90vh;
  margin: 5vh 5vw;
 }

 .contact-box-title{
  margin: 30px 30px 0px 30px !important;
  font-size: 22px;
 }

 .checkbox{
  font-size: 18px;
 }

 .skill-modal-img{
  width: 70%;
 }

 .modal-img-text-wrapper{
  flex-direction: column;
  align-items: center;
  
 }

 .skill-modal-paragraf{
  width: 80%;
 }


}


@media screen and (min-width: 1500px) {

  #offer-section{
    height: 93vh ;
   }

   .skill-modal-img{
    width: 550px;
   }
  
  }